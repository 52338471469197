<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 2322 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_494_176)">
      <path d="M2321.76 0H1175.56V1146.21H2321.76V0Z" fill="#652D90" />
      <path
        d="M1495.23 408.892C1495.23 361.12 1464.66 341.524 1420.45 341.524H1361.35V565.299H1404.98V475.083H1405.58L1459.89 565.299H1512.71L1449.5 470.922C1479.47 465.886 1495.18 437.392 1495.18 408.892M1410.6 444.513H1404.96V377.14H1410.92C1434.06 377.14 1451.58 382.484 1451.58 410.084C1451.58 437.397 1434.66 444.513 1410.62 444.513"
        fill="white"
      />
      <path
        d="M1537.38 383.07C1542.25 383.07 1547.01 381.626 1551.06 378.92C1555.11 376.213 1558.27 372.366 1560.13 367.865C1562 363.365 1562.48 358.412 1561.53 353.634C1560.58 348.856 1558.24 344.467 1554.79 341.023C1551.35 337.578 1546.96 335.232 1542.18 334.282C1537.4 333.332 1532.45 333.819 1527.95 335.683C1523.45 337.548 1519.6 340.705 1516.9 344.755C1514.19 348.806 1512.74 353.568 1512.74 358.44C1512.74 361.677 1513.37 364.884 1514.6 367.876C1515.83 370.869 1517.65 373.588 1519.94 375.877C1522.23 378.166 1524.95 379.98 1527.94 381.214C1530.93 382.449 1534.14 383.08 1537.38 383.07Z"
        fill="white"
      />
      <path d="M1558.15 419.582H1516.6V565.297H1558.15V419.582Z" fill="white" />
      <path
        d="M1632.64 570.05C1640.29 570.049 1647.85 568.313 1654.74 564.971C1661.63 561.63 1667.67 556.77 1672.41 550.758H1673V560.267C1673 582.232 1672.41 608.049 1643.02 608.049C1635.86 608.329 1628.86 605.831 1623.5 601.076C1618.13 596.321 1614.81 589.676 1614.22 582.53H1570.29C1572.37 621.408 1608.29 643.079 1643.9 643.079C1698.53 643.079 1714.53 603.898 1714.53 556.414V419.582H1672.99V433.527H1672.39C1667.93 427.765 1662.22 423.09 1655.69 419.851C1649.16 416.612 1641.98 414.894 1634.7 414.825C1591.37 414.825 1567.03 453.41 1567.03 493.481C1567.03 532.657 1589.87 570.05 1632.62 570.05M1643.61 451.633C1664.96 451.633 1678.02 472.986 1678.02 492.592C1678.02 512.197 1664.97 533.248 1643.61 533.248C1622.24 533.248 1609.18 512.182 1609.18 492.592C1609.18 473.001 1622.23 451.633 1643.61 451.633Z"
        fill="white"
      />
      <path
        d="M1463.18 685.073C1477.12 676.174 1482.77 657.76 1482.77 641.755C1482.77 597.231 1457.24 580.907 1415.69 580.907H1361.38V804.682H1429.94C1470.6 804.682 1501.45 785.097 1501.45 741.168C1501.45 716.537 1489.57 689.83 1463.16 685.053M1404.99 615.907H1410.63C1429.02 615.907 1442.08 621.866 1442.08 644.71C1442.08 667.861 1431.7 674.093 1410.33 674.093H1404.99V615.907ZM1414.19 769.643H1404.99V707.32H1412.11C1434.07 707.32 1457.8 708.507 1457.8 737.612C1457.8 766.718 1437.61 769.662 1414.17 769.662"
        fill="white"
      />
      <path
        d="M1555.77 674.103H1555.18V658.961H1513.63V804.692H1555.18V739.096C1555.18 716.546 1558.16 691.021 1586.93 691.021C1593.08 690.968 1599.1 692.836 1604.14 696.365L1609.19 657.784C1603.72 655.55 1597.88 654.341 1591.97 654.219C1584.73 654.096 1577.59 655.864 1571.24 659.347C1564.89 662.831 1559.56 667.909 1555.77 674.083"
        fill="white"
      />
      <path d="M1662.9 658.971H1621.35V804.686H1662.9V658.971Z" fill="white" />
      <path
        d="M1783.39 672.322H1782.8C1777.9 666.568 1771.8 661.963 1764.93 658.834C1758.06 655.704 1750.58 654.128 1743.02 654.216C1701.18 654.216 1676.54 692.499 1676.54 731.084C1676.54 770.558 1700.88 809.442 1743.62 809.442C1751.13 809.6 1758.57 808.021 1765.37 804.827C1772.16 801.634 1778.13 796.913 1782.8 791.033H1783.39V804.684H1824.94V565.326H1783.39V672.322ZM1753.1 772.634C1731.75 772.634 1718.67 751.281 1718.67 731.675C1718.67 712.07 1731.73 691.019 1753.1 691.019C1774.47 691.019 1787.52 712.085 1787.52 731.675C1787.52 751.266 1774.47 772.634 1753.1 772.634Z"
        fill="white"
      />
      <path
        d="M1942.75 672.915H1942.16C1937.69 667.155 1931.98 662.48 1925.46 659.242C1918.93 656.004 1911.75 654.286 1904.46 654.218C1861.13 654.218 1836.79 692.798 1836.79 732.863C1836.79 772.045 1859.63 809.443 1902.39 809.443C1910.04 809.441 1917.6 807.704 1924.49 804.362C1931.38 801.019 1937.42 796.159 1942.16 790.145H1942.75V799.625C1942.75 821.59 1942.16 847.407 1912.77 847.407C1905.6 847.687 1898.61 845.187 1893.24 840.43C1887.87 835.673 1884.55 829.026 1883.97 821.878H1840.04C1842.11 860.761 1878.03 882.427 1913.65 882.427C1968.27 882.427 1984.27 843.251 1984.27 795.767V658.97H1942.73L1942.75 672.915ZM1913.37 772.635C1892.02 772.635 1878.94 751.56 1878.94 731.974C1878.94 712.389 1892 691.02 1913.37 691.02C1934.74 691.02 1947.79 712.374 1947.79 731.974C1947.79 751.575 1934.73 772.635 1913.37 772.635Z"
        fill="white"
      />
      <path
        d="M2135.95 733.459C2135.95 689.531 2114.6 654.213 2066.79 654.213C2019.61 654.213 1996.16 688.944 1996.16 733.171C1996.16 777.398 2023.76 809.448 2068.87 809.448C2099.44 809.448 2124.96 793.12 2135.05 764.034L2096.77 757.802C2091.13 769.378 2082.52 777.666 2068.87 777.666C2047.21 777.666 2040.07 756.893 2040.07 738.495H2135.91L2135.95 733.459ZM2041.27 712.687C2043.35 697.545 2052.55 683.298 2069.17 683.298C2085.2 683.298 2094.7 697.843 2096.77 712.687H2041.27Z"
        fill="white"
      />
      <path d="M1146.97 0H0.761719V1146.21H1146.97V0Z" fill="#652D90" />
      <path
        d="M547.733 473.271C547.733 424.068 527.428 341.524 391.431 341.524H237.447L353.386 540.34H249.971V804.682H321.51V605.012H391.033L506.243 804.682H586.512L464.435 594.395C518.375 576.021 547.733 533.706 547.733 473.271ZM430.339 536.075L354.111 406.201H391.431C466.139 406.201 476.214 440.098 476.214 473.271C476.214 508.762 462.359 528.065 430.339 536.075Z"
        fill="white"
      />
      <path
        d="M867.147 573.11C895.453 550.54 910.281 516.543 910.281 473.295C910.281 424.092 889.965 341.554 753.969 341.554H612.515V406.22H753.964C828.671 406.22 838.752 440.127 838.752 473.295C838.752 506.462 828.671 540.365 753.964 540.365H612.515V605.915H753.964C828.671 605.915 838.752 639.812 838.752 672.985C838.752 706.157 828.671 740.055 753.964 740.055H612.515V804.691H753.964C889.961 804.691 910.277 722.143 910.277 672.94C910.277 629.662 895.468 595.67 867.142 573.125"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_494_176">
        <rect width="2321" height="1146.21" fill="white" transform="translate(0.761719)" />
      </clipPath>
    </defs>
  </svg>
</template>
